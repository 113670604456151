import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import hemneoColors from '../utils/hemneoColors';


const IndexInfo = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
`

const CategoryCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 8px;
  align-items: stretch;
  justify-content: center;
`
const CategoryCard = styled.a`
  position: relative;
  border: 1px solid #ccc;
  color: ${hemneoColors.black};
  text-decoration: none;
  border: 1px solid ${hemneoColors.B4};
  box-shadow: 0 4px 4px rgba(0,0,0,.2);
  padding-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
`

const CategoryCount = styled.div`
  background-color: ${hemneoColors.B5};
  padding: 8px;
  border-radius: 8px;
  font-size: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  font-weight: bold;
  color: ${hemneoColors.B2}
`

const CategoryImg = styled.img`
  width: 100%;
  height: auto;
  margin: 0;
  min-width: 100%;
  background: ${hemneoColors.B5};
`

const CategoryTitle = styled.h2`
  font-size: 24px;
  line-height: 1.5;
  margin: 0;
  font-weight: bold;
  text-align: left;
  display: block;
  text-decoration: none;
  color: ${hemneoColors.B2};
  margin: 0 8px;
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="" />
      <IndexInfo>
        Vad säger 🤖 att bostadsbilderna visar?
      </IndexInfo>
      <CategoryCards>
      {data.allSitePage.edges.map(edge => (
        <CategoryCard href={edge.node.path}>
          <CategoryImg
            width="126"
            height="94"
            src={`https://bilder.hemnet.se/images/itemgallery_M/${edge.node.context.imagePaths[Math.floor(Math.random() * edge.node.context.imagePaths.length)]}`}
            alt={edge.node.context.categoryName}
          />
          <CategoryCount>
            {edge.node.context.imagePaths.length} { edge.node.context.imagePaths.length === 1 ? 'bild' : 'bilder' }
          </CategoryCount>
          <CategoryTitle>
            {edge.node.context.categoryName}
          </CategoryTitle>
        </CategoryCard>
      ))}
      </CategoryCards>
    </Layout>
  );
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    allSitePage(filter: {context: {categoryName: {ne: null}}}) {
      edges {
        node {
          path
          context {
            categoryName
            imagePaths
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query HomePageQuery {
//     site {
//       siteMetadata {
//         description
//       }
//     }
//   }
// `

